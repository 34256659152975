import React from "react"
import styled from "@emotion/styled"
import theme from "../../shared/theme"
import HoverFlag from "../../elements/hoverflag.js"
import ImageRequest from "../imageRequest/imageRequest.js"

const ImageContainer = ({
  imageTitle,
  imageYear,
  altText,
  imageUrl,
  picOnclick,
  imageRatio,
  isForSale
}) => {
  return (
    <>
      {imageRatio > 1 ? (
        <div className="hoverContainer">
          <Picture
            role="img"
            title={imageTitle}
            src={imageUrl}
            alt={altText}
            onClick={picOnclick}
            className="small"
            aria-label={imageTitle}
            tabIndex={0}
          />
          <ImageSub>
            {imageRatio < 1 ? <div></div> : <HoverFlag picture={imageTitle} />}
            <TitleAndRequestContainer>
              <p>{imageTitle ? imageTitle : "no title"}</p>
              {isForSale && isForSale.imageForSale ? (
                <ImageRequest
                  imageUrl={imageUrl && imageUrl}
                  smallSize={isForSale.smallFormat && isForSale.smallFormat}
                  bigSize={isForSale.bigFormat && isForSale.bigFormat}
                  smallSizePrice={
                    isForSale.price_smallFormat && isForSale.price_smallFormat
                  }
                  bigSizePrice={
                    isForSale.price_bigFormat && isForSale.price_bigFormat
                  }
                  standardPaper={
                    isForSale.standardPaper && isForSale.standardPaper
                  }
                  highArtPaper={
                    isForSale.specialPaper && isForSale.specialPaper
                  }
                  bigFormatImageSize={
                    isForSale.bigFormatImageSize && isForSale.bigFormatImageSize
                  }
                  smallFormatImageSize={
                    isForSale.smallFormatImageSize &&
                    isForSale.smallFormatImageSize
                  }
                />
              ) : (
                <div></div>
              )}
            </TitleAndRequestContainer>
          </ImageSub>
        </div>
      ) : (
        <div className="hoverContainer">
          <Picture
            img="role"
            title={imageTitle}
            src={imageUrl}
            alt={altText}
            onClick={picOnclick}
            className="big"
            aria-label={imageTitle}
            tabIndex={0}
          />
          <ImageSub>
            {imageRatio < 1 ? <div></div> : <HoverFlag picture={imageTitle} />}
            <TitleAndRequestContainer>
              <p>{imageTitle ? imageTitle : "no title"}</p>
              {isForSale && isForSale.imageForSale && (
                <ImageRequest
                  imageUrl={imageUrl && imageUrl}
                  smallSize={isForSale.smallFormat && isForSale.smallFormat}
                  bigSize={isForSale.bigFormat && isForSale.bigFormat}
                  smallSizePrice={
                    isForSale.price_smallFormat && isForSale.price_smallFormat
                  }
                  bigSizePrice={
                    isForSale.price_bigFormat && isForSale.price_bigFormat
                  }
                  standardPaper={
                    isForSale.standardPaper && isForSale.standardPaper
                  }
                  highArtPaper={
                    isForSale.specialPaper && isForSale.specialPaper
                  }
                  bigFormatImageSize={
                    isForSale.bigFormatImageSize && isForSale.bigFormatImageSize
                  }
                  smallFormatImageSize={
                    isForSale.smallFormatImageSize &&
                    isForSale.smallFormatImageSize
                  }
                />
              )}
            </TitleAndRequestContainer>
          </ImageSub>
        </div>
      )}
    </>
  )
}

const Picture = styled.img`
  max-width: 100%;
  max-height: 30vw;

  .small {
    max-height: 50%;
  }

  .big {
    max-height: 80%;
  }

  @media all and (max-width: 768px) {
    max-height: 80vw;
  }
`

const ImageSub = styled.div`
  width: 99%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1%;

  p {
    font-size: ${theme.fontsizes.textMediumScreen};
  }

  @media all and (max-width: 768px) {
    p {
      font-size: ${theme.fontsizes.textSmallScreen};
    }
  }
`

const TitleAndRequestContainer = styled.div`
  display: flex;

  @media all and (max-width: 768px) {
  }
`

export default ImageContainer
