import React from "react"
import styled from "@emotion/styled"
import theme from "../../shared/theme"
import ImageContainer from "./imageContainer.js"

const SmallPictureContainer = ({
  imagetitle,
  imageyear,
  alttext,
  imageurl,
  renderindex,
  piconclick,
  imageratio,
  isForSale
}) => {
  return (
    <React.Fragment>
      <SmallPic key={renderindex}>
        <ImageContainer
          imageTitle={imagetitle}
          imageYear={imageyear}
          altText={alttext}
          imageUrl={imageurl}
          picOnclick={piconclick}
          imageRatio={imageratio}
          isForSale={isForSale}
        />
      </SmallPic>
    </React.Fragment>
  )
}

const SmallPic = styled.div`
  max-width: 60%;

  .hoverContainer {
    width: 100%;
    height: 100%;
  }

  .hover-flag {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.darkFont};
    border-radius: 50%;
    border-color: ${theme.colors.darkFont};
    font-size: ${theme.fontsizes.textMediumScreen};
    width: ${theme.fontsizes.textMediumScreen};
    height: ${theme.fontsizes.textMediumScreen};
    margin-left: 2vw;
    border: ${theme.lineStrength.seperators} solid ${theme.colors.darkFont};
    opacity: 0;
  }

  .hoverContainer:hover .hover-flag {
    opacity: 1;
    transform: translateX(-2vw);
    transition: 0.8s;
    transition-delay: 0.2s;
  }

  @media all and (max-width: 768px) {
    max-width: 100%;

    .hover-flag {
      display: none;
    }
  }
`

export default SmallPictureContainer
