import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import * as BlockContent from "@sanity/block-content-to-react"
import Modal from "react-awesome-modal"
import theme from "../shared/theme"
import styled from "@emotion/styled"
import LargePictureContainer from "../components/imageTile/largePictureContainer"
import SmallPictureContainer from "../components/imageTile/smallPictureContainer"
import { Body, StyledLink } from "../shared/styledElements"
import BlockRenderer from "../utils/textRenderer.js"
import Layout from "../shared/layout.js"

export const query = graphql`
  query($slug: String) {
    sanityProjectStreetphotography(slug: { current: { eq: $slug } }) {
      project_headline
      project_category
      alt_text
      land
      preview_image {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      description
      _rawProjectText
      _rawPictureGallery(resolveReferences: { maxDepth: 25 })
      picture_gallery {
        isForSale {
          imageForSale
          specialPaper
          standardPaper
          smallFormatImageSize
          smallFormat
          price_smallFormat
          price_bigFormat
          deliveryCost_small
          deliveryCost_big
          bigFormatImageSize
          bigFormat
        }
      }
    }
  }
`

export default ({ data }) => {
  const [visible, setVisible] = React.useState(false)
  const [modalIndex, setModalIndex] = React.useState(null)

  const toggleModal = index => {
    setVisible(!visible)
    setModalIndex(index)
  }

  const defaultDescription = `Street Photography aus ${data.sanityProjectStreetphotography.project_headline} in ${data.sanityProjectStreetphotography.land}. Urbane Szenen, alltägliche und kuriose Szenen im Stil der Street Photography aus ${data.sanityProjectStreetphotography.project_headline}`

  return (
    <Layout>
      <Helmet>
        <html lang="de" />
        <title>
          {data
            ? `Street Photography ${data.sanityProjectStreetphotography.project_headline}`
            : "Street Photography Europa"}
        </title>
        <meta
          name="description"
          content={
            data.sanityProjectStreetphotography.description
              ? data.sanityProjectStreetphotography.description
              : defaultDescription
          }
        ></meta>
      </Helmet>
      <Body>
        <div id="modal-root">
          <FirstRow>
            <Headline>
              <h1>
                {data.sanityProjectStreetphotography.project_category
                  ? data.sanityProjectStreetphotography.project_category
                  : ""}
              </h1>
              <h2>
                {data.sanityProjectStreetphotography.project_headline
                  ? data.sanityProjectStreetphotography.project_headline
                  : ""}
              </h2>
            </Headline>
            <StyledLink aria-label="zurück zur Startseite" to="/">
              ZUR STARTSEITE
            </StyledLink>
          </FirstRow>
          <ProjectText>
            <div>
              <BlockContent
                blocks={
                  data.sanityProjectStreetphotography._rawProjectText
                    ? data.sanityProjectStreetphotography._rawProjectText
                    : ""
                }
                serializers={{ types: { block: BlockRenderer } }}
              />
            </div>
          </ProjectText>
          <Seperator />
          <PictureGrid>
            {data.sanityProjectStreetphotography._rawPictureGallery === null
              ? "sorry, no pictures"
              : data.sanityProjectStreetphotography._rawPictureGallery.map(
                  (item, index) => (
                    <Element key={index}>
                      <PictureContainer>
                        {index % 3 === 0 ? (
                          <LargePictureContainer
                            imagetitle={item.image_title}
                            imageyear={item.image_year}
                            alttext={item.alt_text}
                            imageurl={item.image.asset.url}
                            renderindex={index}
                            piconclick={() => toggleModal(index)}
                            imageratio={
                              item.image.asset.metadata.dimensions.aspectRatio
                            }
                            isForSale={item.isForSale && item.isForSale}
                          />
                        ) : (
                          <SmallPictureContainer
                            imagetitle={item.image_title}
                            imageyear={item.image_year}
                            alttext={item.alt_text}
                            imageurl={item.image.asset.url}
                            renderindex={index}
                            piconclick={() => toggleModal(index)}
                            imageratio={
                              item.image.asset.metadata.dimensions.aspectRatio
                            }
                            isForSale={item.isForSale && item.isForSale}
                          />
                        )}
                        {/* Seitenlabel auf jeder 2. Seite links */}
                        {index % 2 === 0 ? (
                          <SeitenText>
                            {data.sanityProjectStreetphotography
                              .project_headline
                              ? data.sanityProjectStreetphotography
                                  .project_category +
                                " | " +
                                data.sanityProjectStreetphotography
                                  .project_headline
                              : ""}
                          </SeitenText>
                        ) : (
                          ""
                        )}
                      </PictureContainer>
                      {item.image_year && (
                        <YearContainer>
                          <span>{item.image_year && item.image_year}</span>
                        </YearContainer>
                      )}
                      <BottomSpacer />
                    </Element>
                  )
                )}
          </PictureGrid>

          <ModalContainer>
            {visible ? (
              <Modal
                key={modalIndex}
                visible={visible}
                width="66%"
                onClickAway={() => toggleModal(modalIndex)}
              >
                {/* Modals Bildvergrößerungen für Hochkantbilder sprengen den Bildschirm - werden deshalb disabled */}
                {data.sanityProjectStreetphotography._rawPictureGallery[
                  modalIndex
                ].image.asset.metadata.dimensions.aspectRatio < 1
                  ? setVisible(false)
                  : null}
                <Picture
                  src={
                    data.sanityProjectStreetphotography._rawPictureGallery[
                      modalIndex
                    ].image.asset.url
                  }
                />
                <span>
                  {
                    data.sanityProjectStreetphotography._rawPictureGallery[
                      modalIndex
                    ].image_title
                  }
                </span>
              </Modal>
            ) : null}
          </ModalContainer>
          <StyledLink aria-label="zurück zur Startseite" to="/">
            ZUR STARTSEITE
          </StyledLink>
        </div>
      </Body>
    </Layout>
  )
}

const BottomSpacer = styled.div`
  width: 100%;
  height: ${theme.spaces.standardPadding};
  background-color: ${theme.colors.bodyBackground};
`

const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Headline = styled.div`
  font-family: ${theme.fontFamilyHeading};
  text-transform: uppercase;
  padding-bottom: ${theme.spaces.standardPadding};

  h2 {
    font-size: calc(2 * ${theme.fontsizes.h1MediumScreen});
  }

  h1 {
    font-size: ${theme.fontsizes.subBannerMediumScreen};
    font-weight: lighter;
  }

  @media all and (max-width: 768px) {
    h2 {
      font-size: calc(3 * ${theme.fontsizes.h1MediumScreen});
    }

    h1 {
      font-size: calc(2 * ${theme.fontsizes.subBannerMediumScreen});
    }
  }
`

const ProjectText = styled.div`
  width: calc(100% / 3 * 2);
  margin-left: calc(100% / 3 * 1);
  border-left: ${theme.lineStrength.seperators} solid ${theme.colors.darkFont};

  div {
    font-size: ${theme.fontsizes.textMediumScreen};
    line-height: ${theme.spaces.textMediumScreenLineHeight};
    text-align: justify;
    padding-left: 1vw;
  }

  @media all and (max-width: 768px) {
    width: calc(100% / 5 * 4);
    margin-left: calc(100% / 5 * 1);

    div {
      font-size: ${theme.fontsizes.textSmallScreen};
      line-height: ${theme.spaces.textSmallScreenLineHeight};
    }
  }
`

const Seperator = styled.div`
  height: 0.25vw;
  width: 100%;
  border-bottom: ${theme.lineStrength.seperators} solid ${theme.colors.darkFont};
  margin: ${theme.spaces.standardPadding} 0 ${theme.spaces.standardPadding} 0;
`

const PictureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(calc(100% / 2 - ${theme.spaces.standardPadding}), 1fr)
  );

  @media all and (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(calc(100% / 1), 1fr));
  }
`

//Achtung, das sind die Blätter/der Rahmen - die Bildgröße wird in den Bildcontainern, also woanders eingestellt
const Element = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: space-between;
  border: 2px solid ${theme.colors.bodyBackground};
  height: 50vw;
  background-color: ${theme.colors.white};

  @media all and (max-width: 768px) {
    height: 100vw;
  }
`
//Hier wird das Bild in einem Container innerhalb des Blattes positioniert
const PictureContainer = styled.div`
  width: 100%;
  height: 50vw;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media all and (max-width: 768px) {
    height: 100vw;
  }
`
//Modal Picture - important
const Picture = styled.img`
  width: 100%;
`

const ModalContainer = styled.div`
  font-size: ${theme.fontsizes.textMediumScreen};
  text-align: center;
  width: 100%;

  @media all and (max-width: 768px) {
    display: none;
  }
`

const SeitenText = styled.span`
  writing-mode: vertical-lr;
  transform: rotate(-180deg);
  font-size: ${theme.fontsizes.smallTextMediumScreen};
  font-weight: bold;
  position: absolute;
  bottom: 33%;
  left: 0;
  z-index: 1;

  @media all and (max-width: 768px) {
    font-size: ${theme.fontsizes.smallTextSmallScreen};
  }
`

const YearContainer = styled.div`
  text-align: right;
  color: #a31e31;
  height: 2.5vw;

  @media all and (max-width: 768px) {
    height: 6vw;
  }

  span {
    font-size: ${theme.fontsizes.smallTextMediumScreen};
    font-weight: bold;
    border-left: 1px ${theme.colors.counterColorRed} solid;
    height: 1.5vw;
    width: 2.5vw;
    padding: ${theme.spaces.buttonPaddingTopBigScreen}
      ${theme.spaces.buttonPaddingSideBigScreen};

    @media all and (max-width: 768px) {
      font-size: ${theme.fontsizes.smallTextSmallScreen};
      font-weight: normal;
    }
  }
`
