import React from "react";

const HoverFlag = () => {
	return (
		<React.Fragment>
			<div aria-hidden="true" className="hover-flag" tabIndex={-1}>+</div>
		</React.Fragment>
	);
};

export default HoverFlag;